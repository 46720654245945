(function ($) {

    $(document).ready(function () {

        var $form = get_stripe_form();

        if($form) {

            $form.submit(function (event) {

                var card = $form.find('#card').val();
                if(card.length > 0) {
                    // Disable the submit button to prevent repeated clicks:
                    $form.find('.submit').prop('disabled', true);

                    // Request a token from Stripe:
                    Stripe.card.createToken($form, stripeResponseHandler);

                    // Prevent the form from being submitted:
                    return false;
                }
            });

        }

    });

    function get_stripe_form(){

        var $forms = $('.stripe-form');
        if($forms.length > 1){
            alert('too many stripe forms');

            return null;
        }

        return $forms;
    }

    function stripeResponseHandler(status, response) {

        var $form = get_stripe_form();

        if (response.error) { // Problem!

            // Show the errors on the form:
            $form.find('.payment-errors strong').text(response.error.message);
            $form.find('.payment-errors').show();
            $form.find('.submit').prop('disabled', false); // Re-enable submission

        } else { // Token was created!

            // Get the token ID:
            var token = response.id;

            // Insert the token ID into the form so it gets submitted to the server:
            $form.append($('<input type="hidden" name="stripeToken">').val(token));

            // Submit the form:
            $form.get(0).submit();
        }
    }
})(jQuery);
/* Application global objects */
window.BIDS = window.BIDS || {};

/*
 Sweet alert
 */
(function () {

    var _swal                 = window.swal;
    var close                 = window.swal.close;
    var previousWindowKeyDown = window.onkeydown;

    window.swal = function () {
        var previousWindowKeyDown = window.onkeydown;
        _swal.apply(this, Array.prototype.slice.call(arguments, 0));
        window.onkeydown = previousWindowKeyDown;
    };

    $('body').on('click', '.sa-button-container button', function () {
        $('body').removeClass('stop-scrolling');
    })
})();

BIDS.alert = function (options, callback) {
    swal(options, callback);
};

/*
 Get the URL for the person's gravatar.
 */
BIDS.gravatarUrl = function (email, size, defaultImage, allowedRating, forceDefault) {

    email         = typeof email !== 'undefined' ? email : 'john.doe@example.com';
    size          = (size >= 1 && size <= 2048) ? size : 80;
    defaultImage  = typeof defaultImage !== 'undefined' ? defaultImage : 'mm';
    allowedRating = typeof allowedRating !== 'undefined' ? allowedRating : 'x';
    forceDefault  = forceDefault === true ? 'y' : 'n';

    return ("https://secure.gravatar.com/avatar/" + md5(email.toLowerCase().trim()) + "?size=" + size + "&default=" + encodeURIComponent(defaultImage) + "&rating=" + allowedRating + (forceDefault === 'y' ? "&forcedefault=" + forceDefault : ''));
};

// Pub/sub for javascript events.
//
BIDS.events = (function () {
    var topics = {};

    return {
        subscribe: function (topic, listener) {
            // Create the topic's object if not yet created
            if (!topics[topic]) topics[topic] = {queue: []};

            // Add the listener to queue
            var index = topics[topic].queue.push(listener) - 1;

            // Provide handle back for removal of topic
            return {
                remove: function () {
                    delete topics[topic].queue[index];
                }
            };
        },
        publish  : function (topic, info) {
            // If the topic doesn't exist, or there's no listeners in queue, just leave
            if (!topics[topic] || !topics[topic].queue.length) return;

            // Cycle through topics queue, fire!
            var items = topics[topic].queue;
            items.forEach(function (item) {
                item(info || {});
            });
        }
    };
})();


(function ($) {
    $(document).ready(function () {

        // Enable all the confirm dialogs
        //
        $('.confirm').click(function (e) {

            var el = $(this);

            e.preventDefault();

            swal({
                title             : 'Are you sure?',
                text              : "You won't be able to revert this!",
                type              : 'warning',
                showCancelButton  : true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor : '#d33',
                confirmButtonText : 'Yes, delete it!'
            }, function (inputValue) {
                if (inputValue === false) return false;

                var form = el.closest('form');
                if (form) {
                    form.submit();
                }
            });
        })
    });
})(jQuery);
// Application global objects
//
window.BIDS = window.BIDS || {};

BIDS.cache = {};

BIDS.cache._data = [];

BIDS.cache.set = function (key, value) {

    BIDS.cache._data[key] = value;
};

BIDS.cache.exists = function (key) {

    return typeof(BIDS.cache._data[key]) != 'undefined';
};

BIDS.cache.get = function (key) {

    return BIDS.cache._data[key];
};


BIDS.cache.clear = function () {

    BIDS.cache._data = [];
};